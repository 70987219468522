import { BrowserRouter, Navigate, Outlet, Route, RouteProps, Routes, redirect } from 'react-router-dom';
import TokenServices from '../services/token';
import { ReactNode, useEffect, useState } from 'react';
import LoginPage from './auth/login';
import API from '../services/api-service';
import ApiEndpoints from '../services/api-endpoints';
import LeadListingPage from './leads';
import LeadEntryPage from './leads/entry';
import { useAuth } from '../hooks/use-auth';
import { Layout, Spin } from 'antd';
import { NotFoundPage } from './notfound/Loadable';
import AppLayout from '../components/Layout';
import SettingPage from './settings';
import CategorySourcePage from './settings/cat-source';
import StatisticsPage from './statistics';
import { HomePage } from './home';
import ChangePasswordPage from './settings/change-password';
import PermissionPage from './permission';

const ProtectedRoute = ({ roles, children }: any) => {
  const { isAuthenticated, loading, userInfo } = useAuth();
  if (loading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spin />
      </div>
    );
  }
  if (!isAuthenticated) {
    return <Navigate to={{ pathname: '/login' }} replace />;
  }
  if (roles?.length > 0) {
    if (!roles.includes(userInfo?.role)) {
      return <Navigate to={{ pathname: '/notfound' }} replace />;
    }
  }
  return <Outlet />;
};

const RouteUser = ({ children }: any) => {
  const { isAuthenticated, loading, userInfo } = useAuth();

  if (loading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spin />
      </div>
    );
  }
  if (isAuthenticated) {
    return <Navigate to={{ pathname: '/leads' }} replace />;
  }
  return children;
};

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <RouteUser>
              <LoginPage />
            </RouteUser>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        >
          <Route
            path="/"
            element={
              <AppLayout>
                <HomePage />
              </AppLayout>
            }
          />
          <Route
            path="/leads"
            element={
              <AppLayout>
                <LeadListingPage />
              </AppLayout>
            }
          />
          <Route
            path="/leads/:id"
            element={
              <AppLayout>
                <LeadEntryPage />
              </AppLayout>
            }
          />
          <Route
            path="/settings"
            element={
              <AppLayout>
                <SettingPage />
              </AppLayout>
            }
          />
          <Route
            path="/settings/sources"
            element={
              <AppLayout>
                <CategorySourcePage />
              </AppLayout>
            }
          />
          <Route
            path="/settings/types"
            element={
              <AppLayout>
                <CategorySourcePage apiUrl="/api/categories/types" title="Danh mục: Nguồn khách hàng" />
              </AppLayout>
            }
          />
          <Route
            path="/settings/products"
            element={
              <AppLayout>
                <CategorySourcePage apiUrl="/api/products" title="Quản lý: Danh sách sản phẩm" />
              </AppLayout>
            }
          />
          <Route
            path="/settings/product-category"
            element={
              <AppLayout>
                <CategorySourcePage apiUrl="/api/products/categories" title="Quản lý: Danh mục sản phẩm" />
              </AppLayout>
            }
          />
          <Route
            path="/settings/change-password"
            element={
              <AppLayout>
                <ChangePasswordPage />
              </AppLayout>
            }
          />
          <Route
            path="/statistics"
            element={
              <AppLayout>
                <StatisticsPage />
              </AppLayout>
            }
          />
          <Route
            path="/permissions"
            element={
              <AppLayout>
                <PermissionPage />
              </AppLayout>
            }
          />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
