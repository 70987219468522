import React, { useState } from 'react';
import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  LineChartOutlined,
  PoweroffOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import TokenService from '../services/token';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

type SidebarProps = {
  show: boolean;
};
const Sidebar = ({ show, ...props }: SidebarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const user = TokenService.getUser();
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const navigate = useNavigate();
  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);
  const onLogout = () => {
    TokenService.clearLocalStorage();
    navigate('/login');
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`z-40 hover:w-[220px] transition-all duration-300 fixed top-0 left-0 h-screen w-[72px] shadow bg-white overflow-x-hidden`}
    >
      <a
        href="/"
        className="ml-[18px] mr-[18px] flex items-center mt-[6px] mb-[6px] text-2xl font-semibold text-gray-900"
      >
        <img className="w-8 h-8 mr-[18px]" src="/favicon.png" alt="logo" />
        {isOpen ? <h2 className="transition">Homekit</h2> : null}
      </a>
      <ul className="space-y-2 font-medium">
        <li>
          <a
            href="/"
            className="ml-[18px] mr-[18px] flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <HomeOutlined style={{ fontSize: 20 }} />
            {isOpen && <span className="flex-1 ms-3 transition whitespace-nowrap">Trang chủ</span>}
          </a>
        </li>
        <li>
          <a
            href="/leads"
            className="ml-[18px] mr-[18px] flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <UserOutlined style={{ fontSize: 20 }} />
            {isOpen && <span className="flex-1 ms-3 whitespace-nowrap">Leads</span>}
          </a>
        </li>
        {user.role == 1 && (
          <li>
            <a
              href="/statistics"
              className="ml-[18px] mr-[18px] flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <LineChartOutlined style={{ fontSize: 20 }} />
              {isOpen && <span className="flex-1 ms-3 whitespace-nowrap">Thống kê</span>}
            </a>
          </li>
        )}
        {user.role == 1 && (
          <li>
            <a
              href="/permissions"
              className="ml-[18px] mr-[18px] flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <ShareAltOutlined style={{ fontSize: 20 }} />
              {isOpen && <span className="flex-1 ms-3 whitespace-nowrap">Phân quyền</span>}
            </a>
          </li>
        )}
        <li>
          <a
            href="/settings"
            className="ml-[18px] mr-[18px] flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <SettingOutlined style={{ fontSize: 20 }} />
            {isOpen && <span className="flex-1 ms-3 whitespace-nowrap">Settings</span>}
          </a>
        </li>
      </ul>
      <div className="absolute bottom-0 left-0 w-full mb-[12px]">
        <ul>
          <li>
            <a
              className="ml-[18px] mr-[18px] flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group"
              onClick={() => setShowConfirmLogout(true)}
            >
              <PoweroffOutlined style={{ fontSize: 20, color: 'red' }} />
              {isOpen && <span className="flex-1 ms-3 transition whitespace-nowrap text-red-500">Đăng xuất</span>}
            </a>
          </li>
        </ul>
      </div>
      <Modal title="Xác nhận" open={showConfirmLogout} onOk={onLogout} onCancel={() => setShowConfirmLogout(false)}>
        <p>Bạn có muốn đăng xuất ?</p>
      </Modal>
    </div>
  );
};

export default Sidebar;
