import React, { useState } from 'react';
import type { FormProps } from 'antd';
import { Button, Checkbox, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import apiEndpoints from '../../services/api-endpoints';
import API from '../../services/api-service';
import TokenService from '../../services/token';
import { useNavigate } from 'react-router-dom';

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

type LoginResponseData = {
  accessToken: string;
  refreshToken: string;
  profile: {
    id: number;
    fullName: string;
    role: number;
    status: number;
    username: string;
    lastLogin: string;
  };
};

const LoginPage: React.FC = () => {
  const [form] = Form.useForm();
  const [username, setUsername] = useState(TokenService.getLastUsername());
  const navigate = useNavigate();
  const onLogin = async () => {
    const data = form.getFieldsValue();
    data.loginType = 'EMAIL';

    const response = await API.post<LoginResponseData>(apiEndpoints.login, data);

    if (data.remember) {
      TokenService.setRememberLogin(true);
    }
    TokenService.updateAccessToken(response.data.accessToken);
    TokenService.updateRefreshToken(response.data.refreshToken);
    TokenService.setUser(response.data.profile);
    return navigate('/leads');
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img
            className="w-8 h-8 mr-2"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
            alt="logo"
          />
          Homekit Portal
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Đăng nhập
            </h1>
            <Form
              form={form}
              onFinish={onLogin}
              initialValues={{
                email: username,
              }}
              layout="vertical"
              className="space-y-4 md:space-y-6"
            >
              <Form.Item
                label="Email"
                name="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                rules={[{ required: true, message: 'Vui lòng nhập email!' }]}
              >
                <Input
                  placeholder="name@khoacuahomekit.com"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </Form.Item>
              <Form.Item
                label="Mật khẩu"
                name="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
              >
                <Input.Password
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </Form.Item>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <Form.Item name="remember" valuePropName="checked" initialValue={true} className="mb-0">
                    <Checkbox>Ghi nhớ đăng nhập</Checkbox>
                  </Form.Item>
                </div>
                <a href="#" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">
                  Quên mật khẩu?
                </a>
              </div>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-full h-[40px]">
                  Đăng nhập
                </Button>
              </Form.Item>

              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Vui lòng liên hệ admin nếu chưa có tài khoản
              </p>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
