class TokenService {
  setLastUsername(username: string) {
    localStorage.setItem('last_username', username);
  }
  getLastUsername() {
    return localStorage.getItem('last_username');
  }
  getRememberLogin(): boolean {
    const remember = localStorage.getItem('login_remember') === 'true';
    return remember ? remember : false;
  }
  setRememberLogin(remember: boolean) {
    localStorage.setItem('login_remember', JSON.stringify(remember));
  }
  getRefreshToken(): string {
    const remember = this.getRememberLogin();
    let refreshToken;
    if (remember) refreshToken = localStorage.getItem('refresh_token');
    else {
      refreshToken = sessionStorage.getItem('refresh_token');
      localStorage.removeItem('refresh_token');
    }
    return refreshToken ? refreshToken : '';
  }
  getAccessToken(): string {
    const remember = this.getRememberLogin();
    let refreshToken;
    if (remember) refreshToken = localStorage.getItem('access_token');
    else {
      refreshToken = sessionStorage.getItem('access_token');
      localStorage.removeItem('access_token');
    }
    return refreshToken ? refreshToken : '';
  }
  updateAccessToken(token: string) {
    const remember = this.getRememberLogin();
    if (remember) localStorage.setItem('access_token', token);
    else sessionStorage.setItem('access_token', token);
  }

  updateRefreshToken(token: string) {
    const remember = this.getRememberLogin();
    if (remember) localStorage.setItem('refresh_token', token);
    else sessionStorage.setItem('refresh_token', token);
  }

  getUser() {
    const user = localStorage.getItem('user');
    const userJson = user ? JSON.parse(user) : null;
    return userJson;
  }

  setUser(user: object) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  clearLocalStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }
}

export default new TokenService();
