import { Button, Col, Form, Input, Row, Spin, Typography, notification } from 'antd';
import { useState } from 'react';
import apiService from 'src/services/api-service';

const ChangePasswordPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function update() {
    setLoading(true);
    let values = form.getFieldsValue();
    try {
      const response = await apiService.put(`/api/users/change_password`, values);
      if (response.status >= 400) {
        throw new Error(response.data.message);
      }
      notification.success({
        message: 'Thông báo',
        description: 'Cập nhật thành công',
      });
      form.resetFields();
    } catch (err: any) {
      notification.error({
        message: 'Lỗi',
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Spin spinning={loading}>
      <div className="text-center">
        <Typography.Title level={3}>Thay đổi mật khẩu</Typography.Title>
      </div>
      <Form
        layout="vertical"
        form={form}
        style={{ padding: 16, marginBottom: 20 }}
        scrollToFirstError={true}
        initialValues={{ active: true }}
        size="large"
      >
        <Row gutter={[30, 0]}>
          <Col span={8} offset={8}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  name="oldPassword"
                  label="Mật khẩu cũ"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mật khẩu',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Mật khẩu mới"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mật khẩu',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="confirmPassword"
                  label="Nhập lại mật khẩu"
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mật khẩu xác nhận',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Mật khẩu mới không trùng khớp'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row style={{ justifyContent: 'flex-end', marginTop: 20 }}>
                  <Button
                    type="primary"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => update()}
                  >
                    Thay đổi
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ChangePasswordPage;
