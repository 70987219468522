import React from 'react';
import ReactDOM from 'react-dom/client';
import { redirect } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';
import App from './app';
import { HelmetProvider } from 'react-helmet-async';
import dayjs from 'dayjs';
import { ConfigProvider, theme } from 'antd';
require('dayjs/locale/vi');
dayjs.locale('vi');
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorBorder: '#d9d9d9',
            colorErrorActive: '#ff2d54',
            colorErrorBg: '#ff2d5414',
            colorErrorBorder: '#ff2d54',
            colorErrorText: '#ff2d54',

            colorPrimary: '#34B129',
            // colorPrimaryHover: '#33d9b3',

            colorText: '#141414',
            colorSuccessText: '#51B848',
            colorWarningText: '#ff590d',
            colorSuccessBg: '#51B84833',
            colorInfoBgHover: '#e5e5e5',
            colorLinkHover: '#141414',
            colorWarningBg: '#FF590D1A',
          },
        }}
      >
        <App />
      </ConfigProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
