import * as React from 'react';

import { lazyLoad } from '../../utils/loadable';
import { Spin } from 'antd';

export const NotFoundPage = lazyLoad(
  () => import('./index'),
  module => module.NotFoundPage,
  {
    fallback: (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spin />
      </div>
    ),
  },
);
