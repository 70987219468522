export const DEMAND_OPTIONS = [
  {
    value: 0,
    label: 'Không có nhu cầu',
  },
  {
    value: 1,
    label: 'Có nhu cầu',
  },
  {
    value: 2,
    label: 'Rác',
  },
];
