import { Button, Layout } from 'antd';
import { PropsWithChildren, useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Sidebar from './Sidebar';
import Header from './Header';

export default function AppLayout({ children }: PropsWithChildren) {
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <Layout className="bg-white">
      <Sidebar show={showSidebar} />

      <Header />
      <Layout.Content className="min-h-screen bg-white ml-[72px] mt-[60px] p-[12px]">{children}</Layout.Content>
    </Layout>
  );
}
