import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import TokenService from '../../services/token';

export default function SettingPage() {
  const user = TokenService.getUser();
  return (
    <div className="p-16">
      <Row gutter={[16, 16]}>
        {user.role == 1 && (
          <>
            <Col md={12} sm={24} xs={24}>
              <Link to="/settings/sources">
                <Card
                  title="Quản lý"
                  styles={{
                    header: {
                      backgroundColor: '#E0FBE2',
                      color: '#000',
                    },
                  }}
                >
                  Nguồn khách hàng
                </Card>
              </Link>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Link to="/settings/types">
                <Card
                  title="Quản lý"
                  styles={{
                    header: {
                      backgroundColor: '#E0FBE2',
                      color: '#000',
                    },
                  }}
                >
                  Nhóm khách hàng
                </Card>
              </Link>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Link to="/settings/products">
                <Card
                  title="Quản lý"
                  styles={{
                    header: {
                      backgroundColor: '#E0FBE2',
                      color: '#000',
                    },
                  }}
                >
                  Sản phẩm
                </Card>
              </Link>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Link to="/settings/product-category">
                <Card
                  title="Quản lý"
                  styles={{
                    header: {
                      backgroundColor: '#E0FBE2',
                      color: '#000',
                    },
                  }}
                >
                  Danh mục sản phẩm
                </Card>
              </Link>
            </Col>
          </>
        )}
        <Col md={12} sm={24} xs={24}>
          <Link to="/settings/change-password">
            <Card
              title="Đổi mật khẩu"
              styles={{
                header: {
                  backgroundColor: '#E0FBE2',
                  color: '#000',
                },
              }}
            >
              Thay đổi mật khẩu
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  );
}
