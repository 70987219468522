import { Avatar, Layout, Badge, Divider } from 'antd';
import { useAuth } from 'src/hooks/use-auth';
import { BellOutlined } from '@ant-design/icons';

function getFirstCharsRegex(str: string) {
  const words = str.split(' ');
  if (words.length < 2) return words[0][0]; // Handle case with less than 2 words
  return words[0][0] + words[1][0];
}
export default function Header() {
  // return <></>;
  const { isAuthenticated, loading, userInfo } = useAuth();

  return (
    <Layout.Header className="fixed top-0 left-[72px] bg-white shadow z-20 h-[60px] inset-0 flex justify-end items-center">
      <div className="px-[24px] cursor-pointer">
        <Badge count={100} className="mt-[32px]">
          <BellOutlined style={{ fontSize: 20 }} />
        </Badge>
      </div>
      {/* <Divider type="vertical" className="h-[24px]" /> */}
      <div className="cursor-pointer items-center ">
        <Avatar>{getFirstCharsRegex(userInfo?.fullName ?? '')}</Avatar>
        <span className="ml-[6px]">{userInfo?.fullName}</span>
      </div>
    </Layout.Header>
  );
}
