import { useEffect, useState } from 'react';
import TokenServices from '../services/token';
import API from '../services/api-service';
import API_ENDPOINT from '../services/api-endpoints';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<any>(undefined);

  useEffect(() => {
    const token = TokenServices.getAccessToken();
    const checkTokenValidity = async () => {
      if (token) {
        try {
          const response = await API.get(API_ENDPOINT.self);
          if (response.status === 200) {
            setIsAuthenticated(true);
            setUserInfo(response.data);
          } else {
          }
        } catch (error) {
          setIsAuthenticated(false);
        }
      }
      setLoading(false);
    };
    checkTokenValidity();
  }, []);

  return { isAuthenticated, loading, userInfo };
};
