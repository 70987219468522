import { Button, Col, Form, Input, Modal, Row, Table, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { LeadSource } from '../../types/lead.types';
import apiService from '../../services/api-service';

type CategoryPageProps = {
  apiUrl?: string;
  title?: string;
};

export default function CategorySourcePage(props: CategoryPageProps) {
  const apiUrl = props.apiUrl || '/api/categories/sources';
  const [data, setData] = useState<LeadSource[]>([]);
  const [currentEditItem, setCurrentEditItem] = useState<LeadSource>();
  const [isCreateNewItem, setIsCreateNewItem] = useState(false);
  const [form] = Form.useForm();

  async function getAllLeadSources() {
    const response = await apiService.get<LeadSource[]>(apiUrl);
    const sources = response.data;
    setData(sources);
  }
  const onEditItem = (item: LeadSource) => {
    setCurrentEditItem(item);
    form.setFieldsValue(item);
  };
  const onPressCreateNewItem = () => {
    setIsCreateNewItem(true);
  };

  const onCreateNewItem = async () => {
    try {
      const submitData = form.getFieldsValue();
      const response = await apiService.post(apiUrl, submitData);
      if (response.status == 200) {
        notification.success({ message: 'Thêm danh mục thành công' });
      }
      form.resetFields();
      setIsCreateNewItem(false);
    } catch (error: any) {
      notification.error({ message: 'Lỗi thêm mới danh mục' });
      console.error(error?.message);
    }
  };

  const onUpdateItem = async () => {
    try {
      const submitData: LeadSource = form.getFieldsValue();
      submitData.id = currentEditItem!!.id;
      const response = await apiService.put(`${apiUrl}/${currentEditItem!!.id}`, submitData);
      if (response.status == 200) {
        notification.success({ message: 'Cập nhật thành công' });
      }
      onCloseModal();
      getAllLeadSources();
    } catch (error: any) {
      notification.error({ message: 'Lỗi thêm mới' });
      console.error(error?.message);
    }
  };

  const onCloseModal = () => {
    setCurrentEditItem(undefined);
    setIsCreateNewItem(false);
    form.resetFields();
  };
  useEffect(() => {
    getAllLeadSources();
  }, []);

  return (
    <div className="p-[24px]">
      <Typography.Title level={4}>{props.title || 'Danh mục: Nguồn khách hàng'}</Typography.Title>
      <div className="mb-[16px]">
        <Button size="large" onClick={onPressCreateNewItem}>
          Tạo mới
        </Button>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table
            dataSource={data}
            columns={[
              {
                title: '#ID',
                key: 'id',
                dataIndex: 'id',
              },
              {
                title: 'Name',
                key: 'name',
                dataIndex: 'name',
              },
              {
                title: 'Mô tả',
                key: 'description',
                dataIndex: 'description',
              },
              {
                title: '',
                render: record => {
                  return <></>;
                },
              },
            ]}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => onEditItem(record),
              };
            }}
          />
        </Col>
      </Row>
      <Modal
        open={!!currentEditItem || isCreateNewItem}
        destroyOnClose={true}
        cancelText="Hủy"
        okText={isCreateNewItem ? 'Lưu' : 'Cập nhật'}
        title={isCreateNewItem ? 'Tạo mới' : 'Cập nhật'}
        onCancel={() => onCloseModal()}
        onOk={isCreateNewItem ? onCreateNewItem : onUpdateItem}
      >
        <div className="p-2">
          <Form form={form}>
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Mô tả" name="description">
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
