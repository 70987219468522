/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import tokenService from './token';
import ApiEndpoints from 'src/services/api-endpoints';

const apiHost = process.env.REACT_APP_BASE_API_URL;

export function refreshTokenApi(token: string) {
  return axios
    .post(
      ApiEndpoints.refreshToken,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        baseURL: apiHost,
      },
    )
    .then(responseToken => {
      return responseToken;
    });
}
class ApiService {
  _refreshTokenRequest: Promise<AxiosResponse> | null;
  public instance: AxiosInstance;
  constructor() {
    this._refreshTokenRequest = null;
    this.instance = axios.create({
      baseURL: apiHost,
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json',
        platform: 'WEB',
      },
    });
    this.instance.interceptors.request.use(
      (config: any) => {
        const accessToken: string = tokenService.getAccessToken();
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        config.headers.platform = 'WEB';
        return config;
      },
      (error: any) => Promise.reject(error),
    );
    this.instance.interceptors.response.use(this.handleSuccess, this.handleError);
  }

  handleSuccess = (response: any) => response;
  // eslint-disable-next-line consistent-return
  handleError = async (error: any) => {
    if (error.response) {
      if (error.response.status === 401 && error.response.data.message === 'Unauthorized') {
        const originalConfig = error.config;
        const refreshToken = tokenService.getRefreshToken();
        if (
          refreshToken &&
          originalConfig.url !== ApiEndpoints.login &&
          originalConfig.url !== ApiEndpoints.refreshToken &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          this._refreshTokenRequest = this._refreshTokenRequest
            ? this._refreshTokenRequest
            : refreshTokenApi(refreshToken);

          const newTokenResponse = await this._refreshTokenRequest;
          if (newTokenResponse && newTokenResponse.status === 200) {
            tokenService.updateAccessToken(newTokenResponse.data.accessToken);
            tokenService.updateRefreshToken(newTokenResponse.data.refreshToken);
            setTimeout(() => {
              this._refreshTokenRequest = null;
            }, 10);
            return this.instance(originalConfig);
          } else {
            tokenService.clearLocalStorage();
            window.location.pathname = '/login';
            setTimeout(() => {
              this._refreshTokenRequest = null;
            }, 10);
          }
        } else {
          tokenService.clearLocalStorage();
          window.location.pathname = '/login';
        }
      }

      return error.response;
    }

    // Swal.fire('Error', 'API Error', 'error');
    Promise.reject(error);
  };
}

export default new ApiService().instance;
