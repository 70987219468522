'use client';

import { Lead, LeadSource, LeadType, SaleInfo } from '../../types/lead.types';
import API from '../../services/api-service';
import TokenService from '../../services/token';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
  TimeRangePickerProps,
  Tooltip,
  notification,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { DEMAND_OPTIONS } from 'src/constants/lead';
import apiService from '../../services/api-service';
import { RocketOutlined } from '@ant-design/icons';

interface Filters {
  page: number;
  pageSize: number;
  keyword: number;
}
const LEAD_HOT_DATE = {
  HOT: 7,
  WARM: 30,
  COLD: 60,
};
const LEAD_STATUS = [
  {
    value: 0,
    label: 'Mới',
  },
  {
    value: 1,
    label: 'FL lần 1',
  },
  {
    value: 2,
    label: 'FL lần 2',
  },
  {
    value: 3,
    label: 'Đã chốt',
  },
  {
    value: 4,
    label: 'Đã lên đơn',
  },
  {
    value: 5,
    label: 'Từ chối',
  },
];
export default function LeadListingPage() {
  const user = TokenService.getUser();
  const now = moment();
  const navigate = useNavigate();
  const location = useLocation();
  const search = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'comma',
    });
  }, [location]);
  const FILTERS_DEFAULT = {
    page: 1,
    pageSize: search?.pageSize || 10,
    keyword: search?.keyword || '',
  };
  const [leads, setLeads] = useState<Lead[]>([]);
  const [totalLead, setTotalLead] = useState(0);
  const [productCategories, setProductCategories] = useState<any>([]);
  const [leadSources, setLeadSources] = useState<any>([]);
  const [leadTypes, setLeadTypes] = useState<any>([]);
  const [filters, setFilters] = useState<Filters & any>(FILTERS_DEFAULT);
  const [filterForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [saleOptions, setSaleOptions] = useState<any[]>([]);

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Lead nóng', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Lead ấm', value: [dayjs().add(-30, 'd'), dayjs().add(-7, 'd')] },
    { label: 'Lead nguội', value: [null, dayjs().add(-30, 'd')] },
  ];

  const getLeads = async (filters: Filters & any) => {
    try {
      console.log(filters);
      setLoading(true);
      const response = await API.get('/api/leads', {
        params: filters ?? {},
      });
      setLeads(response.data.rows);
      setTotalLead(response.data.count);
    } catch (error: any) {
      notification.error({
        message: 'Lỗi',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  async function getCategories() {
    try {
      const response = await API.get('/api/products/categories');
      setProductCategories(response.data ?? []);
    } catch (err: any) {
      notification.error({
        message: 'Lỗi',
        description: err.message,
      });
    }
  }

  async function getAllLeadSources() {
    const response = await API.get<LeadSource[]>('/api/categories/sources');
    const data = response.data;
    setLeadSources(data);
  }

  async function getAllLeadTypes() {
    const response = await API.get<LeadType[]>('/api/categories/types');
    const data = response.data;
    setLeadTypes(data);
  }
  async function getAllSales() {
    const response = await API.get<SaleInfo[]>('/api/users/sales');
    const data = response.data;
    setSaleOptions(
      data.map(s => ({
        value: s.id,
        label: s.fullName,
        status: s.status,
      })),
    );
  }

  const onSelectItem = (item: any) => {
    navigate(`/leads/${item.leadId}`);
  };

  const changeUrlQueryString = (filters: any) => {
    navigate(
      '/leads?' + queryString.stringify(filters, { arrayFormat: 'comma', skipEmptyString: true, skipNull: true }),
    );
  };

  const onTableChange = (pagination: any, tableFilters: any, sorter: any) => {
    const newFilters = {
      ...filters,
      ...tableFilters,
      page: pagination.current,
      pageSize: pagination.pageSize,
    };
    filters.page = pagination.current;
    filters.pageSize = pagination.pageSize;
    changeUrlQueryString(newFilters);
  };

  const onSearch = useCallback(
    debounce(filters => {
      getLeads(filters);
    }, 500),
    [],
  );

  const onChangeFormFilter = (e: any) => {
    const formValues = filterForm.getFieldsValue();
    const filterDates = {
      followDateFrom: formValues.lastFollow?.[0]
        ? (formValues.lastFollow[0] as dayjs.Dayjs).format('YYYY-MM-DD')
        : null,
      followDateTo: formValues.lastFollow?.[1] ? (formValues.lastFollow[1] as dayjs.Dayjs).format('YYYY-MM-DD') : null,
      contactDateFrom: formValues.contactDate?.[0]
        ? (formValues.contactDate[0] as dayjs.Dayjs).format('YYYY-MM-DD')
        : null,
      contactDateTo: formValues.contactDate?.[1]
        ? (formValues.contactDate[1] as dayjs.Dayjs).format('YYYY-MM-DD')
        : null,
    };
    delete formValues.contactDate;
    delete formValues.lastFollow;
    console.warn(formValues, filters);
    const newFilters = {
      ...filters,
      ...formValues,
      ...filterDates,
      page: 1,
    };

    debounceUpdateFilters(newFilters);
  };

  const debounceUpdateFilters = useCallback(
    debounce(filters => {
      changeUrlQueryString(filters);
    }, 500),
    [filters],
  );

  const onChangeContactDate = (dates: any, dateStrings: string[]) => {
    return;
    const newFilters = {
      ...filters,
      page: filters.page,
      contactDateFrom: dates[0] ? (dates[0] as dayjs.Dayjs).format('YYYY-MM-DD') : null,
      contactDateTo: dates[1] ? (dates[1] as dayjs.Dayjs).format('YYYY-MM-DD') : null,
    };
    // setFilters(newFilters);
  };
  const onChangeFollowDate = (dates: any, dateStrings: string[]) => {
    return;
    const newFilters = {
      ...filters,
      page: filters.page,
      followDateFrom: dates[0] ? (dates[0] as dayjs.Dayjs).format('YYYY-MM-DD') : null,
      followDateTo: dates[1] ? (dates[1] as dayjs.Dayjs).format('YYYY-MM-DD') : null,
    };
    // setFilters(newFilters);
  };
  const onClearFilter = () => {
    filterForm.resetFields();
    filterForm.setFieldsValue({
      demand: null,
      productCategory: null,
      source: null,
      keyword: null,
      contactDateFrom: null,
      contactDateTo: null,
    });
    debounceUpdateFilters(FILTERS_DEFAULT);
  };

  const onExportCSV = async (mode: number) => {
    const response = await apiService.post('/api/leads/export_csv', {
      mode: mode,
    });
    const csvBlob = new Blob([response.data], { type: 'text/csv' });
    const csvUrl = window.URL.createObjectURL(csvBlob);

    const link = document.createElement('a');
    link.href = csvUrl;
    link.setAttribute('download', `Danh sách lead_${dayjs().format('DD-MM-YYYY')}.csv`); // Set your desired filename

    // Simulate a click on the hidden anchor tag to start the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the temporary URL to avoid memory leaks
    window.URL.revokeObjectURL(csvUrl);
  };

  const reContactLead = async (leadId: number) => {
    setLoading(true);
    try {
      const submitData: any = {
        leadId: leadId,
        conversations: [
          {
            conversationId: 0,
            content: 'ĐÃ RẢI LẠI',
          },
        ],
        lastFollow: Date.now(),
      };

      const response = await API.put(`/api/leads/${leadId}`, submitData);
      if (response.status === 200) {
        notification.success({
          message: 'Cập nhật lead thành công',
        });
      }
    } catch (error: any) {
      notification.error({
        message: error.message || 'Không thể cập nhật lead, vui lòng thử lại sau',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onSearch(filters);
  }, [filters]);

  useEffect(() => {
    const refinedFilters: any = {};
    Object.keys(search).forEach(key => {
      if (['phoneNumber', 'productCategory', 'source'].includes(key) && search[key]) {
        if (Array.isArray(search[key])) {
          refinedFilters[key] = Object.values(search[key]!).map(x => +x!);
        } else {
          refinedFilters[key] = [+search[key]!];
        }
      } else if (key.indexOf('From') > 1 || key.indexOf('To') > 1) {
        console.log('search[key]', search[key]);
        try {
          refinedFilters[key] = dayjs(search[key] as string, 'YYYY-MM-DD');
        } catch (error) {
          console.log('ERROR', error);
        }
      } else {
        if (Array.isArray(search[key]))
          refinedFilters[key] = Object.values(search[key]!).map(x => (!isNaN(+x!) ? +x! : x));
        else {
          refinedFilters[key] = search[key];
        }
      }
      setFilters(refinedFilters);

      setKeyword(refinedFilters.keyword);
      filterForm.setFieldsValue({
        ...refinedFilters,
        contactDateFrom: null,
        contactDateTo: null,
        followDateFrom: null,
        followDateTo: null,
        contactDate: [refinedFilters.contactDateFrom, refinedFilters.contactDateTo],
        lastFollow: [refinedFilters.followDateFrom, refinedFilters.followDateTo],
      });
    });
  }, [search]);

  useEffect(() => {
    getCategories();
    getAllLeadSources();
    getAllLeadTypes();
    getLeads(filters);
    getAllSales();
  }, []);

  const tableColumns = [
    {
      render: (record: Lead) => {
        return (
          <Card onClick={e => onSelectItem(record)}>
            <div className="mb-[6px] flex justify-between">
              <span>
                <strong>Lead ID:</strong>
                {record.leadId}
              </span>
              <span>
                <strong>Ngày liên hệ: </strong>
                {moment(record.contactDate).format('DD-MM-YYYY')}
              </span>
            </div>

            <div className="mb-[6px]">
              <strong>Khách hàng:</strong>
              {record.name}
            </div>

            <div className="mb-[6px]">
              <strong>Điện thoại:</strong>
              {record.phoneNumber?.map(phone => <Tag key={phone}>{phone}</Tag>)}
            </div>

            <div className="mb-[6px]">
              <strong>Nhu cầu:</strong>
              {(() => {
                if (record.demand == 1) {
                  return <Tag color="green">Có nhu cầu</Tag>;
                }
                if (record.demand == 0) {
                  return <Tag color="blue">Không có nhu cầu</Tag>;
                }
                return <Tag color="red">Rác</Tag>;
              })()}
            </div>

            <div className="mb-[6px]">
              <strong>Nội dung:</strong>
              {record.description}
            </div>

            <div className="mb-[6px]">
              <strong>Tình trạng:</strong>
              {(() => {
                const status = record.status;
                if (status == 0) {
                  return <Tag color="blue">Mới</Tag>;
                }
                if (status == 1) {
                  return <Tag color="green">FL lần 1</Tag>;
                }
                if (status == 2) {
                  return <Tag color="blue">FL lần 2</Tag>;
                }
                if (status == 3) {
                  return <Tag color="blue-inverse">Đã chốt</Tag>;
                }
                if (status == 4) {
                  return <Tag color="gold-inverse">Đã lên đơn</Tag>;
                }
                return <Tag color="red">Từ chối</Tag>;
              })()}
            </div>

            <div className="mb-[6px]">
              <strong>FL gần nhất:</strong>
              {record.lastFollow ? moment(record.lastFollow).format('DD-MM-YYYY') : null}
            </div>

            <div className="mb-[6px]">
              <strong>Người phụ trách:</strong> {record.saleInfo?.fullName || ''}
            </div>
          </Card>
        );
      },
      responsive: ['xs'],
    },
    {
      title: 'Ngày liên hệ',
      dataIndex: 'contactDate',
      key: 'contactDate',
      // sorter: (a, b) => moment(a.contactDate).valueOf() - moment(b.contactDate).valueOf(),

      render: (date: string) => {
        const hotDate = moment(date);
        let color = 'red-inverse';
        const diffDate = now.diff(hotDate, 'days');
        if (diffDate >= 7) {
          color = 'cyan-inverse';
        } else if (diffDate >= 30) {
          color = 'cyan-inverse';
        }
        return (
          <Tag color={color}>
            {hotDate.isSame(now, 'year') ? hotDate.format('DD-MM') : hotDate.format('DD-MM-YYYY')}
          </Tag>
        );
      },
      responsive: ['md'],
    },
    {
      title: 'Mã KH',
      // dataIndex: 'leadId',
      key: 'leadId',
      // sorter: (a, b) => a.leadId - b.leadId,
      width: 100,
      responsive: ['md'],
      render: (record: Lead) => {
        return (
          <Button type="link" block onClick={e => onSelectItem(record)}>
            #{record.leadId}
          </Button>
        );
      },
    },

    {
      title: 'Tên liên lạc',
      dataIndex: 'name',
      key: 'name',
      // sorter: (a, b) => a.name.localeCompare(b.name),
      // width: 'calc(100% - 300px)',
      filtered: true,
      responsive: ['md'],
    },
    {
      title: 'Liên hệ',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (phoneNumber: string[]) => {
        return <>{phoneNumber?.map(phone => <Tag key={phone}>{phone}</Tag>)}</>;
      },
      responsive: ['md'],
    },
    {
      title: 'Nhu cầu',
      dataIndex: 'demand',
      key: 'demand',
      render: (status: number) => {
        if (status == 1) {
          return <Tag color="green">Có nhu cầu</Tag>;
        }
        if (status == 0) {
          return <Tag color="blue">Chưa xác định</Tag>;
        }
        if (status == 2) {
          return <Tag color="cyan">Chưa nhu cầu</Tag>;
        }
        return <Tag color="red">Rác</Tag>;
      },
      responsive: ['md'],
    },
    {
      title: 'Nội dung',
      dataIndex: 'description',
      responsive: ['md'],
      width: 300,
    },
    {
      title: 'Ngành hàng',
      dataIndex: 'productCategory',
      key: 'productCategory',
      filtered: true,
      filterSearch: true,
      render: (productCategory: string[]) => {
        return (
          <>
            {productCategory?.map(cat => {
              const catName = productCategories?.find((c: any) => c.id == cat)?.name ?? '';
              return <Tag key={cat}>{catName || cat}</Tag>;
            })}
          </>
        );
      },
      responsive: ['md'],
    },
    {
      title: 'Nguồn khách hàng',
      render: ({ sourceId }: Lead) => {
        if (!sourceId) return <></>;
        const name = leadSources.find((s: any) => s.id === sourceId)?.name ?? '';
        return <Tag>{name}</Tag>;
      },
      responsive: ['md'],
    },

    {
      title: 'Tình trạng',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => {
        if (status == 0) {
          return <Tag color="blue">Mới</Tag>;
        }
        if (status == 1) {
          return <Tag color="green">FL lần 1</Tag>;
        }
        if (status == 2) {
          return <Tag color="blue">FL lần 2</Tag>;
        }
        if (status == 3) {
          return <Tag color="blue">Đã chốt</Tag>;
        }
        if (status == 4) {
          return <Tag color="gold">Đã lên đơn</Tag>;
        }
        return <Tag color="red">Từ chối</Tag>;
      },
      responsive: ['md'],
    },
    {
      title: 'FL gần nhất',
      dataIndex: 'lastFollow',
      key: 'lastFollow',
      // sorter: (a, b) => moment(a.contactDate).valueOf() - moment(b.contactDate).valueOf(),
      width: 150,
      render: (date: string) => {
        return <>{date ? moment(date).format('DD-MM-YYYY') : null}</>;
      },
      responsive: ['md'],
    },
    {
      title: 'Người phụ trách',
      dataIndex: 'saleInfo',
      responsive: ['md'],
      render: (saleInfo: any) => {
        return <>{saleInfo.fullName || ''}</>;
      },
    },
  ];
  if (true || user.role == 3) {
    tableColumns.push({
      title: '',
      responsive: ['md'],
      render: (record: Lead) => {
        return (
          <>
            <Popconfirm title="Rải lại lead" okText="Có" cancelText="Hủy" onConfirm={e => reContactLead(record.leadId)}>
              <Button type="text" icon={<RocketOutlined style={{ color: 'orange', fontSize: 20 }} />}></Button>
            </Popconfirm>
          </>
        );
      },
    });
  }
  return (
    <div className="md:p-8 sm:p-4 sm:pt-12">
      <Row gutter={[0, 32]} className="pb-6">
        <Col span={12}>
          <Link to={'/leads/entry'} className="mr-4">
            <Button size="large" type="primary">
              Tạo lead mới
            </Button>
          </Link>
          <Popover
            // title="Chọn các tùy chọn sau"
            placement="bottomRight"
            content={
              <div className="flex flex-col">
                {/* <Button type="text" onClick={() => onExportCSV(0)}>
                  Theo bộ lọc(30 ngày)
                </Button> */}
                <Button type="text" onClick={() => onExportCSV(1)}>
                  7 ngày gần nhất
                </Button>
                <Button type="text" onClick={() => onExportCSV(2)}>
                  14 ngày gần nhất
                </Button>
              </div>
            }
          >
            <Button type="dashed" size="large">
              Xuất file
            </Button>
          </Popover>
        </Col>
      </Row>
      <Form
        form={filterForm}
        // onChange={onChangeFormFilter}
        initialValues={filters ?? {}}
      >
        <Row gutter={[10, 32]} className="pb-6">
          <Col md={4} sm={12} xs={24}>
            <Form.Item name="keyword" className="mb-0">
              <Input placeholder="Tìm kiếm" />
            </Form.Item>
          </Col>
          <Col md={8} sm={12} xs={24}>
            <Form.Item name="contactDate" className="mb-0">
              <DatePicker.RangePicker
                format={'DD-MM-YYYY'}
                presets={rangePresets}
                maxDate={dayjs()}
                placeholder={['Ngày liên hệ từ', 'đến']}
                className="w-full"
                onChange={onChangeContactDate}
              />
            </Form.Item>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Form.Item name="demand" className="mb-0">
              <Select
                options={DEMAND_OPTIONS}
                placeholder="Nhu cầu"
                // onChange={onChangeFormFilter}
              />
            </Form.Item>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Form.Item name="productCategory" className="mb-0">
              <Select
                options={productCategories.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }))}
                placeholder="Ngành hàng"
                mode="multiple"
                maxTagCount={1}
                // onChange={onChangeFormFilter}
                showSearch={false}
              />
            </Form.Item>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Form.Item name="source" className="mb-0">
              <Select
                options={leadSources.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }))}
                placeholder="Nguồn khách hàng"
                mode="multiple"
                maxTagCount={1}
                // onChange={onChangeFormFilter}
                showSearch={false}
              />
            </Form.Item>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Form.Item name="status" className="mb-0">
              <Select
                options={LEAD_STATUS}
                placeholder="Tình trạng"
                mode="multiple"
                maxTagCount={1}
                // onChange={onChangeFormFilter}
                showSearch={false}
              />
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item name="lastFollow" className="mb-0">
              <DatePicker.RangePicker
                format={'DD-MM-YYYY'}
                maxDate={dayjs()}
                placeholder={['Ngày follow từ', 'đến']}
                className="w-full"
                onChange={onChangeFollowDate}
              />
            </Form.Item>
          </Col>
          {user.role != 3 && (
            <Col md={4} sm={12} xs={24}>
              <Form.Item name="sales" className="mb-0">
                <Select
                  options={saleOptions}
                  optionRender={option => (
                    <Space>
                      {option.data.status == 3 && <Tag color={'red'}>D</Tag>}
                      {option.data.label}
                    </Space>
                  )}
                  placeholder="Sales"
                  mode="multiple"
                  maxTagCount={1}
                  // onChange={onChangeFormFilter}
                  showSearch={false}
                />
              </Form.Item>
            </Col>
          )}
          <Col md={8} sm={8} xs={8}>
            <Form.Item noStyle className="mb-0 mr-2">
              <Button onClick={onChangeFormFilter} className="mr-2" loading={loading}>
                Lọc dữ liệu
              </Button>
            </Form.Item>
            <Form.Item noStyle className="mb-0">
              <Button onClick={onClearFilter} type="dashed" danger loading={loading}>
                Xóa lọc
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        rowKey={'leadId'}
        dataSource={leads}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: e => onSelectItem(record),
        //   };
        // }}
        pagination={{ total: totalLead, current: filters.page }}
        onChange={onTableChange}
        loading={loading}
        columns={tableColumns as any}
      />
    </div>
  );
}
